import { useAmenityContext } from '@/context/Amenity';
import { useCountryContext } from '@/context/Country';
import { useGlobalConfigContext } from '@/context/GlobalConfig';
import { useIpLocationContext } from '@/context/IpLocation';
import { useMetaContext } from '@/context/Meta';
import { useUserContext } from '@/context/User';

export default function useSetupContexts() {
  const userContext = useUserContext();
  const ipLocationContext = useIpLocationContext();
  const globalConfigContext = useGlobalConfigContext();
  const metaContext = useMetaContext();
  const amenityContext = useAmenityContext();
  const countryContext = useCountryContext();

  /*
   * For the purpose of the app setup, currentRoomOrSpaceContext ready state
   * should only matter if we're in a detail page.
   */
  const contexts = [
    countryContext,
    userContext,
    ipLocationContext,
    metaContext,
    globalConfigContext,
    amenityContext,
  ];

  // The app needs state from all of these
  // context before it can load it's main pieces.
  return contexts.filter((it) => !!it);
}
