import LoadingContent from '@/components/helpers/LoadingContent';

import { useUserContext } from '@/context/User';

import useGlobalStateReady from './useGlobalStateReady';

export default function GlobalStateSetupContainer({ children }) {
  const { ready: userReady } = useUserContext();

  const ready = useGlobalStateReady();

  if (!ready) {
    return <LoadingContent placement={!userReady ? 'neutral' : undefined} />;
  }

  return <>{children}</>;
}
