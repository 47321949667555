import useOnRouteChange from '@/hooks/useOnRouteChange';

import { pathMatchRouteName } from '@/lib/routes';

const routesToIgnore = [
  'guestEdit',
  'guestAdd',
  'reservationCancel',
  'reservationModify',
  'reservationModifyConfirm',
  'reservationExtend',
  'reservationExtendConfirm',
  'reservationAddToCalendar',
  'spaceReserve',
  'spaceReserveConfirm',
  'roomReserve',
  'roomReserveConfirm',
  'accountCreditCardEdit',
];

/**
 * Ensures that the window is automatically scrolled to the top
 * when routing, as described in:
 * https://reacttraining.com/react-router/web/guides/scroll-restoration
 */

function ScrollToTop() {
  useOnRouteChange(({ pathname }) => {
    if (routesToIgnore.some((r) => pathMatchRouteName(pathname, r))) {
      return;
    }

    window.scrollTo(0, 0);
  });

  return null;
}

export default ScrollToTop;
